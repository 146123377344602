<template>
	<el-row style="background:#d1e1f9;">

		<div style="width: 70%; margin: 0 auto;">
			<ul>
				<li v-for="(item,index) in newlist" class="list_li" @click="zhifu(item.url)">
					<el-row>
						<el-col :span='8'>
							<div style="width:400px; margin-top: 15px;">
								<img src="../register/img/bg3.jpg" alt="" style="width: 100%;">
							</div>
						</el-col>
						<el-col :span='16'>
							<h1 class="list_title">{{item.title}}</h1>
						</el-col>
						<el-col :span='16'>
							<p class="list_p_one">{{item.content}}</p>
						</el-col>
						<el-col :span='16'>
							<el-col :span='4'>
								<p class="list_p_sha1">【付费¥2元阅读全文】</p>
							</el-col>
							<el-col :span='20'>
								<p class="list_p_sha">{{item.time}}</p>
							</el-col>
						</el-col>
					</el-row>
				</li>
			</ul>
		</div>
		<el-dialog title="支付宝扫一扫可查看" :visible.sync="dialogVisible_zhifu" width="20%" :close-on-click-modal="false"
		 :close-on-press-escape="false">
			<div style="width: 100%;text-align: center;">
				<vue-qr :text="zhifu_img" :size="200"></vue-qr>
			</div>
			<span slot="footer" class="dialog-footer"></span>
		</el-dialog>

	</el-row>
</template>
<script>
	import {
		requestestalipay,
		requestcheck_orderstatus
	} from '../api/request.js'
	import vueQr from 'vue-qr'
	export default {
		components: {
			vueQr
		},
		data() {
			return {
				newlist: [],
				dialogVisible_zhifu: false,
				zhifu_img: "",
			}
		},
		methods: {

			zhifu(e) {
				let _this = this;
					let info = {
						total_amount: 2,
						subject: "测试支付",
						account_id: 0
					}
					this.dialogVisible_zhifu = true
					requestestalipay(info).then(res => {
						_this.zhifu_img = res.ret.qrcode_url;
						this.setmimi = setInterval(function() {
							let info1 = {
								out_trade_no: res.ret.out_trade_no,
								total_amount:2,
								subject: "测试支付",
								account_id: 0
							}
							requestcheck_orderstatus(info1).then(res => {
								if (res.code == 0) {
									_this.$message({
										message: '恭喜你支付成功',
										type: 'success'
									});
									localStorage.setItem("zhifu", 0);
									_this.dialogVisible_zhifu = false
									clearTimeout(_this.setmimi);
									window.open(e)
								}
							})
						}, 1000)
					})

			}
		},
		mounted() {
			let newlist = [{
					title: '建设工程虚拟仿真信息化教学解决方案简介',
					content: ' 睿格致针对高校的教学业务展开设计，以建筑专业教育教学行为特点和高校人才能力的培养为切入点，利用互联网、虚拟仿真技术、大数据等信息化手段为支撑，形成了包括平台建设、资源库建设、课程建设、环境建设四大部分的整体解决方案。最终达到提升建设工程领域的专业教学质量的目的，与各大高校在建筑人才培养、教学改革、信息化建设等方面共同推进...',
					time: '2020-02-24',
					url:"http://www.rgzsoft.com/xinwenzhongxin/20.html"
				},
				{
					title: '三库三实一平台促专业课程教学改革',
					content: '   针对建筑类院校教学业务的分析，从老师和学生两个维度分别进行教学现状的深入调研，考虑现阶段学校的期望，结合我们的平台和虚拟仿真系列课程得出了不同的教学流程中信息化的解决方案，整个方案概括为“三库” “三实”“一平台”。',
					time: '2020-12-13',
                    url:"http://www.rgzsoft.com/xinwenzhongxin/31.html"
                },
				{
					title: '仿真技术工具化，轻松实现建筑专项信息化技能实训',
					content: '   仿真技术在解决建筑工程基础能力以及专业核心能力建设的提升中，主要以解决知识实训为主，随着BIM技术、仿真技术的深度广泛应用，在项目实践过程中对人员对新技术的应用也提出了更高的要求。基于此，结合建设工程施工过程中的虚拟模拟的要求，将虚拟仿真技术工具化，3D素材个性... ',
					time: '2020-12-06',
                    url:"http://www.rgzsoft.com/xinwenzhongxin/33.html"
				},
				{
					title: '实体实训教学——给您一个立体的工程博物馆',
					content: '  鉴于在建筑工程项目施工管理的教学过程中知识点多、杂、细，学生难以理解，教学资源难以达到有效整合及使用，大批量学生难组织等因素。校内实训基地可以通过虚实结合再现企业施工环境、营造职业氛围、跟踪行业高新技术，完成其施工与实训融合的教学功能。...',
					time: '2020-12-02',
                    url:"http://www.rgzsoft.com/xinwenzhongxin/37.html"
				}
			];
			this.newlist = newlist;
		}
	};
</script>
<style scoped>
	.list_li {
		cursor: pointer;
		width: 100%;
		background-color: white;
		height: 200px;
		margin-top: 20px;
		margin-bottom: 20px;
		border-radius: 20px;
		padding: 20px;
	}

	.list_title {
		width: 100%;
		text-align: left !important;
		text-align: center;
		font-weight: bold;
		font-size:20px;
		line-height: 60px;
	}

	.list_p_one {
		font-size: 14px;
		text-align: left;
		color: #C3C3C3;
		margin-top: 10px;
	}

	.list_p_sha {
		width: 100%;
		margin-top: 50px;
		text-align: right;
		font-size: 14px;
		color: #C0C0C0;
	}
	.list_p_sha1 {
		margin-top: 50px;
		font-size: 14px;
		color: #C0C0C0;
	}
</style>
</style>
